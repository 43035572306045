import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/meddbtech.github.io/meddbtech.github.io/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "service"
    }}>{`Service`}</h2>
    <h3 {...{
      "id": "web-application"
    }}>{`Web application`}</h3>
    <p>{`Meddbtech specialize in building applications for the web.
We’ve had experience from static websites through to complex online data web application serving thousands of customers all at once.`}</p>
    <ul>
      <li parentName="ul">{`Highly scalable public web sites`}</li>
      <li parentName="ul">{`Rich, modern, fast interfaces optimised for ease of use and conversions`}</li>
      <li parentName="ul">{`Custom built for your unique business needs`}</li>
    </ul>
    <h3 {...{
      "id": "mobile-application-cross-platform"
    }}>{`Mobile application (Cross-Platform)`}</h3>
    <p>{`Meddtech builds mobile apps for iPad, iPhone, Android and tablet devices.
Almost everyone owning a smartphone, it’s time to investigate the opportunities this presents.`}</p>
    <p>{`We’re experts on new techniques such as responsive design
(websites that automatically adjust when viewed on a mobile device) and are pioneers of new technologies.`}</p>
    <p>{`Our areas of mobile expertise include:`}</p>
    <ul>
      <li parentName="ul">{`Responsive web sites`}</li>
      <li parentName="ul">{`iPhone, iPad and Android native applications`}</li>
      <li parentName="ul">{`Apps built for offline use with synchronization`}</li>
      <li parentName="ul">{`Cross-platform mobile apps built on ionic`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      